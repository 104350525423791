import React, { FC } from "react"
import { PageProps } from "gatsby"
import { Typography } from "@material-ui/core"
import Layout from "../components/layout"

const KontaktPage: FC<PageProps> = () => {
  return (
    <Layout>
      <Typography variant="h4">Kontakt</Typography>
      <Typography>Hier wird ein Kontaktformular entstehen.</Typography>
    </Layout>
  )
}

export default KontaktPage
